import React from "react"
import {graphql} from "gatsby";
import styled from 'styled-components'
// import SEO from "../components/seo"

import PageTemplate from '../components/PageTemplate'
import HomepageBgImages from '../components/HomepageBgImages'

import homeAgenciesGroup from '../images/home_logo_group_2.png'
import homeAgenciesGroupDesktop from '../images/home_logo_group_desktop_2.png'
import circleContainer from '../images/circle-container.png'


// query to load agency data from excel file
// NOTE: graphql queries must exist in a 'page' file...they will not work within a component file
// unless you use a StaticQuery component which has a different signature

//** NOTE: we are loading the locations data separately from the locations column of the second
//** sheet so that users can determine order of locations from the excel doc
export const IndexQuery = graphql`
  query {
    allAgencydataXlsxSheet1 {
      edges {
        node {
          name
          categories
          disciplines
          locations
          headline
          link
          logo
          contact_name
          contact_email
        }
      }
    }
      allAgencydataXlsxSheet2 {
          edges {
              node {
                  locations
              }
          }
      }
  }
`

const PageContent = styled.div`

  position: relative;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    min-height: 770px;
    padding-top: 135px;
    z-index: 1;
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    
  }

`
const PageContentInner = styled.div`
  padding-left: ${props => props.theme.paddingSidesMobile};
  padding-right: ${props => props.theme.paddingSidesMobile};
  padding-top: 90px;
  padding-bottom: 40px;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-left: ${props => props.theme.paddingSidesDesktop};
    padding-right: ${props => props.theme.paddingSidesDesktop};
    padding-top: 0;
    padding-bottom: 0;
  }
  & .placeholder {
    height: 0px;
    content: ' ';
   }
   
  @media (min-width: ${props => props.theme.breakpointTablet}) {
   & .placeholder {
    height:50px;
    content: ' ';
   }
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    & .placeholder {
     height: 18rem;
     content: ' ';
    }
   }

   @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
    & .placeholder {
     height: 46rem;
     content: ' ';
    }
   }
`
const CopyContainer = styled.div`
  position: relative;
  top: 2rem;
  width: 100%;
  margin-bottom: 24rem;

  h1 {
    color: ${props => props.theme.blueDark};
    font-weight: 900;
    font-size: 2.1rem;
    margin-bottom: 5px;
  }

  h2 {
    color: ${props => props.theme.blueDark};
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0rem 0rem 1rem 0rem;
  }
  
  p {
    color: ${props => props.theme.blueDark};
    font-size: .938rem;
    line-height: 1.4;
    width: 90%;
  }

  .home-agencies {
    color: ${props => props.theme.blueDark};
    font-weight: 600;
    font-size: .938rem;
    line-height: 1.4;
    width: 100%;
  }

  img {
    display: block;
    margin: 3rem auto;
    width: 100%;
  }

  .home-agencies-logo-desktop {
    display: none;
  }

  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
      //width: 550px;
      top: unset;
      margin-bottom: 24rem;
      
      h1 {
        font-size: 3.8rem;
        width: 100%;
      }

      h2 {
        font-size: 2rem;
      }
      
      p {
        width: 90%;
        font-size: 1.15rem;
      }

      .home-agencies {
        font-size: 1.15rem;
        width: 100%;
      }

      .home-agencies-logo-mobile {
        display: none;
      }
  
      .home-agencies-logo-desktop {
        display: block;
        padding-bottom: 4rem;
        width: 100%;
      }
  }

  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    top: 3rem;
    margin-bottom: 20px;
    
    h1 {
      font-size: 2.875rem;
      width: 33%;
    }

    h2 {
      width: 30%;
    }
    
    p {
      width: 30%;
      font-size: 1.15rem;
    }

    .home-agencies {
      margin: 1rem 0rem;
      width: 40%;
    }

    .home-agencies-logo-desktop {
      margin: 2rem 0rem;
      width: 35%;
    }
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    top: 8rem; 

    h1 {
      font-size: 2.875rem;
      line-height: 3.125rem;
      width: 35%;
      margin-bottom: 1rem;
    }

    h2 {
      width: 100%;
    }

    p {
      width: 35%;
    }

    .home-agencies-logo-desktop {      
      width: 609px;
      margin: 1rem 0rem;
    }

    .home-agencies {
      margin: 2rem 0rem 0rem 0rem;
      width: 35%;
    }
  }

  @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
    top: 20vh;

    h1 {
      font-size: 3rem;
      width: 30vw;
    }

    h2 {
      font-size: 2.3rem;
    }

    p {
      font-size: 1.5rem;
      width: 38%;
    }

    .home-agencies {
      font-size: 1.5rem;
      width: 38%;
    }

    .home-agencies-logo-desktop {
      width: 38%;
    }
  }

`

const CircleContainer = styled.div`
  position: absolute;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: 100%;
  background-image: url(${circleContainer});
  background-repeat: no-repeat;
  display: none;

  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    left: -6rem;
    display: unset;
    background-size: contain;
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    left: 0rem;
  }
`


const BackgroundContent = styled.div`

  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  // height: 760px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: linear-gradient(to right, #cddbe7, #fff);
  overflow: hidden;
  
  border-bottom-width: 2px;
  border-bottom-color: white;
  border-bottom-style: solid;
  -webkit-box-shadow: 0 0 30px 10px rgba(151,200,230,1);
  box-shadow: 0 0 30px 10px rgba(151,200,230,1);
  
  
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
  
    
    
    border-bottom-width: 2px;
    border-bottom-color: white;
    border-bottom-style: solid;
    -webkit-box-shadow: 0 0 30px 10px rgba(151,200,230,1);
    box-shadow: 0 0 30px 10px rgba(151,200,230,1);
    
   
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    
  }
  
`

const PeopleImages = styled(HomepageBgImages)`
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  // background: radial-gradient(circle, rgba(255,255,255,1) 19%, rgba(255,255,255,0) 36%);
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    background: transparent;
    top: 60%;
  }

  @media (min-width: ${props => props.theme.breakpointDesktop}) {
    top: 3rem;
    left: 10rem;
    float: right;
    width: 150%;
  }

  @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
    top: 0rem;
    left: 5rem;
    float: right;
    width: 115%;
  }

  // @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
  //   top: 7rem;
  // }

`

class IndexPage extends React.Component {

  state = {
    showingResults: false,
    currentView: '',
    matchMediaInstance: undefined,
  }

  componentDidMount() {

    let instance = window.matchMedia('(max-width: 767px)')
    if (instance.addEventListener) {
      instance.addEventListener("change", this.doMatchBreakPoint)
    } else {
      instance.addListener(this.doMatchBreakPoint)
    }

    this.doMatchBreakPoint(instance)

    this.setState({
      matchMediaInstance: instance,
    })
  }

  componentWillUnmount() {
    // removing the matchMedia listener prevent lingering references that cause memory leaks
    // have to check for removeEventListener or removeListener for browser compatibility
    if (this.state.matchMediaInstance.removeEventListener) {
      this.state.matchMediaInstance.removeEventListener("change", this.doMatchBreakPoint)
    } else {
      this.state.matchMediaInstance.removeListener(this.doMatchBreakPoint)
    }
  }

  doMatchBreakPoint = (e) => {
    this.setState(state => {
      // store a flag to determine what breakpoint we are on
      state.currentView = e.matches ? 'mobile' : 'desktop'
    }, () => {
      this.setState({state: this.state})
    })
  }

  doResultsView = () => {
    this.setState(state => {
      state.showingResults = true;
    }, () => {
      // forcing a rerender after state has been updated with new menus
      this.setState({ state: this.state });
    })
  }


  doStartOver = () => {
    this.setState(state => {
      state.showingResults = false;
    }, () => {
      // forcing a rerender after state has been updated with new menus
      this.setState({ state: this.state });
    })
  }

  render() {

      return (
          <PageTemplate metaDataIndex={1} selectedNavIndex={1}>
              {console.log('rerendering index page')}
              {/*<SEO title="Home"/>*/}
              <PageContent>
                  <BackgroundContent>
                    {<PeopleImages/>}
                  </BackgroundContent>
                  <PageContentInner role="main" id="main">
                      <CircleContainer></CircleContainer>
                      <CopyContainer>
                          <h1>Our Health and Wellness&nbsp;Portfolio</h1>
                          <h2>A World of Difference</h2>
                          <p>
                          At IPG, our global health and wellness solutions include 100+ agencies across more than 40 countries—a collective of over 10,100 health communications experts dedicated to making a difference in the lives of our clients and the people they serve. From healthcare professional and consumer marketing, to digital marketing, analytics, public relations, specialty services, technology innovation, and media strategy—we create inspired solutions with exceptional impact.
                          </p>
                          <p className={"home-agencies"}>
                          Our health expertise is anchored by these leading global networks:
                          </p>
                          <img className={"home-agencies-logo-mobile"} src={homeAgenciesGroup} alt=''/>
                          <img className={"home-agencies-logo-desktop"} src={homeAgenciesGroupDesktop} alt=''/>
                          {/* <img className={"home-agencies-logo-desktop"} src={homeAgenciesGroup1} alt=''/>
                          <img className={"home-agencies-logo-desktop home-agencies-row-2"} src={homeAgenciesGroup2} alt=''/> */}
                      </CopyContainer>
                      {/* <SolutionsModuleContainer className={this.state.showingResults ? 'resultsView' : ''}>
                        <SolutionsModule agencyData={this.props.data.allAgencydataXlsxSheet1.edges} agencyLocationsData={this.props.data.allAgencydataXlsxSheet2.edges} resultsViewCallback={this.doResultsView} startoverCallback={this.doStartOver} />
                      </SolutionsModuleContainer> */}
                      {/*<HomepageCta/>*/}
                      <div className={"placeholder"}></div>
                  </PageContentInner>
                  
              </PageContent>
          </PageTemplate>

      )
  }
}

export default IndexPage
