import React from 'react'
import styled from 'styled-components'
import {Tween} from 'react-gsap';

const bgImageOptions = {
  desktop: [
    {image: require('../images/people/person_1.png'), class: 'bg_1'},
    {image: require('../images/people/person_2.png'), class: 'bg_2'},
    {image: require('../images/people/person_3.png'), class: 'bg_3'},
    {image: require('../images/people/person_4.png'), class: 'bg_4'},
    {image: require('../images/people/person_5.png'), class: 'bg_5'},
    {image: require('../images/people/person_6.png'), class: 'bg_6'},
    {image: require('../images/people/person_7.png'), class: 'bg_7'},
    {image: require('../images/people/person_8.png'), class: 'bg_8'},
  ],
  mobile: [
    {image: require('../images/people/mobile/person_1.png'), class: 'bg_1'},
    {image: require('../images/people/mobile/person_2.png'), class: 'bg_2'},
    {image: require('../images/people/mobile/person_3.png'), class: 'bg_3'},
    {image: require('../images/people/mobile/person_4.png'), class: 'bg_4'},
    {image: require('../images/people/mobile/person_5.png'), class: 'bg_5'},
    {image: require('../images/people/mobile/person_6.png'), class: 'bg_6'},
    {image: require('../images/people/mobile/person_7.png'), class: 'bg_7'},
    {image: require('../images/people/mobile/person_8.png'), class: 'bg_8'},
  ]
}

const bgImageNames = [
  {id: 1, name: "Diego, BX, New York City, US"},
  {id: 2, name: "Roberta, Weber Shandwick, São Paulo, Brazil"},
  {id: 3, name: "Thomas, AREA 23, New York City, US"},
  {id: 4, name: "Matthew, Golin, New York City, US"},
  {id: 5, name: "Chris, FCB Health, New York City, US"},
  {id: 6, name: "Noelle, Golin, New York City, US"},
  {id: 7, name: "Dana, IPG Health, New York City, US"},
  {id: 8, name: "Barbara, NEON, New York City, US"}
];

const ImagesContainer = styled.div`
  
  position: absolute;
  top: 80%;

  overflow: hidden; 
  img {
  
    // top: 32%;
    // width: 94%;
    // right: -5%;
    // max-width: 880px;
    
    display: block;
    position: absolute;
    height: auto;
    opacity: 0;
    
    &.show {
      opacity: 1;
    }
    
    &.bg_1 {width: 100%; top: 0rem;}
    &.bg_2 {width: 100%; top: 0rem;}
    &.bg_3 {width: 100%; top: 0rem;}
    &.bg_4 {width: 100%; top: 0rem;}
    &.bg_5 {width: 100%; top: 0rem;}
    &.bg_6 {width: 100%; top: 0rem;}
    &.bg_7 {width: 100%; top: 0rem;}
    &.bg_8 {width: 100%; top: 0rem;}
   
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
      
      border-top-left-radius: 500% 50%;
      border-top-right-radius: 500% 50%;
      
      
      &.bg_1 {width: 100%; top: 0rem;}
      &.bg_2 {width: 100%; top: 0rem;}
      &.bg_3 {width: 100%; top: 0rem;}
      &.bg_4 {width: 100%; top: 0rem;}
      &.bg_5 {width: 100%; top: 0rem;}
      &.bg_6 {width: 100%; top: 0rem;}
      &.bg_7 {width: 100%; top: 0rem;}
      &.bg_8 {width: 100%; top: 0rem;}
    }

    @media (min-width: ${props => props.theme.breakpointDesktop}) {
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
      
      img {
        height: 100%;
      }
      
    }
  }
  
  .bg-person-name {
    position: absolute;
    top: 15%;
    right: 0;
    padding: 0 1rem;
    border-radius: 20px 0 0 20px;
    background-color: white;
    color: ${props => props.theme.blueDark};
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 3.375rem;
    z-index: 10;

    
    
    @media (min-width: ${props => props.theme.breakpointTablet}) {
      top: 30%;
      font-size: 0.9rem;
    }
    
    @media (min-width: ${props => props.theme.breakpointDesktop}) { 
      top: 80%;
      font-size: 1.125rem;
      padding: 0 3rem;
      right: 8rem;
    }

    @media (min-width: ${props => props.theme.breakpointLargeDesktop}) {
      right: 4rem;
      top: 72%;
    }

    @media (min-width: ${props => props.theme.breakpointXLDesktop}) {
      top: 57vh
    }
  
  }
  
`

class HomepageBgImages extends React.Component {

  state = {
    currentView: '',
    // this will be used for the very first image so that it is random
    //currentImageIndex: Math.floor(bgImageOptions.mobile.length * Math.random()),
    currentImageIndex: -1,
    prevImageIndex: undefined,
    matchMediaInstance: undefined,
    imageSwapInterval: undefined,
    imagesJsx: undefined,
    imageSwapMilliseconds: 6500
  }

  componentDidMount() {

    // assigning a handler to handle the triggering of the breakpoint
    let instance = window.matchMedia('(max-width: 767px)')
    if (instance.addEventListener) {
      instance.addEventListener("change", this.doMatchBreakPoint)
    } else {
      instance.addListener(this.doMatchBreakPoint)
    }
    this.doMatchBreakPoint(instance)

    // do the first image swap on load
    //this.doSwitchBgImage()

    // begin the interval that will switch the images
    const interval = setInterval(this.doSwitchBgImage, this.state.imageSwapMilliseconds)

    // store the swap interval and match media instance in our state
    this.setState({
      imageSwapInterval: interval,
      matchMediaInstance: instance,
    })
  }

  componentWillUnmount() {
    // removing the matchMedia listener and imageSwapInterval
    // prevent lingering references that cause memory leaks
    // have to check for removeEventListener or removeListener
    // for browser compatibility
    if (this.state.matchMediaInstance.removeEventListener) {
      this.state.matchMediaInstance.removeEventListener("change", this.doMatchBreakPoint)
    } else {
      this.state.matchMediaInstance.removeListener(this.doMatchBreakPoint)
    }
    clearInterval(this.state.imageSwapInterval)
  }

  doMatchBreakPoint = (e) => {
    this.setState(state => {
      // store a flag to determine what breakpoint we are on
      state.currentView = e.matches ? 'mobile' : 'desktop'
    }, () => {
      // doing an image swap when the breakpoint changes to make sure
      // that the jsx rerenders and show the bg images for that breakpoint
      this.doSwitchBgImage();
    })
  }

  doSwitchBgImage = () => {
    // store the index for the latest bg image
    let prevIndex = this.state.currentImageIndex
    let currIndex = this.state.currentImageIndex == bgImageOptions.desktop.length - 1 ? 0 : this.state.currentImageIndex + 1

    // search for a bg image index until the previous and current one don't match
    // while (prevIndex == currIndex) {
    //   currIndex = Math.floor(bgImageOptions.mobile.length * Math.random())
    // }

    // update our state with the new bg image indexes and new bg image jsx
    this.setState(state => {
      state.prevImageIndex = prevIndex;
      state.currentImageIndex = currIndex;
      state.personName = bgImageNames[currIndex].name;

      state.personNameJsx = <Tween key={this.state.currentImageIndex} from={{opacity: 0}} to={{opacity: 1}} duration={3}>
        <div>{this.state.personName}</div>
      </Tween>;

      // on load, the imagesJsx state prop will not have a value
      // so we just need to insert jsx for one image
      state.imagesJsx = this.state.imagesJsx != undefined ? [
        <Tween key={this.state.prevImageIndex} from={{opacity: 1}} to={{opacity: 0}} duration={3}><img src={bgImageOptions[this.state.currentView][this.state.prevImageIndex].image}
                                                                                                       className={bgImageOptions[this.state.currentView][this.state.prevImageIndex].class}
                                                                                                       alt='One of our great employees!'/></Tween>,
        <Tween key={this.state.currentImageIndex} from={{opacity: 0}} to={{opacity: 1}} duration={3}><img
          src={bgImageOptions[this.state.currentView][this.state.currentImageIndex].image} className={bgImageOptions[this.state.currentView][this.state.currentImageIndex].class}
          alt='One of our great employees!'/></Tween>,
      ] : [
        <Tween key={this.state.currentImageIndex} from={{opacity: 1}} to={{opacity: 1}} duration={3}><img
          src={bgImageOptions[this.state.currentView][this.state.currentImageIndex].image} className={bgImageOptions[this.state.currentView][this.state.currentImageIndex].class}
          alt='One of our great employees!'/></Tween>,
      ]


    }, () => {
      this.setState({state: this.state})
    })
  }

  render() {
    return (
      <ImagesContainer className={this.props.className}>
        {this.state.imagesJsx}
        <div className='bg-person-name'>
          {this.state.personNameJsx}
        </div>
      </ImagesContainer>

    )
  }
}

export default HomepageBgImages
